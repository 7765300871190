<template>
  <p-container class="detail-container">
    <a-row :gutter="24">
      <a-col :span="8">
        <a-card title="直属科研单位" :bordered="false">
          <a-descriptions>
            <a-descriptions-item :span="3" v-for="(item,index) in items" :key="index">
                <template slot="label">
                    <a-badge :color="colors[index]" :text="item" />
                </template>
                <a href="javascript:;">888</a>
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card title="直属产业单位" :bordered="false">
          <a-descriptions>
            <a-descriptions-item :span="3" v-for="(item,index) in items" :key="'100'+index">
                <template slot="label">
                    <a-badge :color="colors[index]" :text="item" />
                </template>
                <a href="javascript:;">888</a>
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card title="直属省公司" :bordered="false">
          <a-descriptions>
            <a-descriptions-item :span="3" v-for="(item,index) in items" :key="'300'+index">
                <template slot="label">
                    <a-badge :color="colors[index]" :text="item" />
                </template>
                <a href="javascript:;">888</a>
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>
      <a-col :span="24">
        <a-card :bordered="false" style="margin-top:24px;">
          <a-table :columns="columns" size="middle" :data-source="data" :pagination="false"></a-table>
        </a-card>
      </a-col>
    </a-row>
  </p-container>
</template>

<script>
const colors = ['orange','gold','yellow','cyan','green','lime'];
const items = ['发明','实用新型','外观设计','核心','重要','一般'];
const columns = [{title: '',dataIndex: 'a'},{title: '发电技术',dataIndex: 'b'},{title: '输电技术',dataIndex: 'c'},{title: '变电技术',dataIndex: 'd'},{title: '配电技术',dataIndex: 'e'},{title: '用电技术',dataIndex: 'f'}]
const data = [
    {key:6, a: '发明',b: 10,c:10,d:10,e:10,f:10},
    {key:5, a: '实用新型',b: 10,c:10,d:10,e:10,f:10},
    {key:4, a: '外观设计',b: 10,c:10,d:10,e:10,f:10},
    {key:3, a: '核心',b: 10,c:10,d:10,e:10,f:10},
    {key:2, a: '重要',b: 10,c:10,d:10,e:10,f:10},
    {key:1, a: '一般',b: 10,c:10,d:10,e:10,f:10}]
export default {
    data(){
        return {
            colors,
            items,
            data,
            columns
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container /deep/{
  .ant-descriptions-item{
    text-align: right;
  }
  .ant-descriptions-item-label{
    float: left;
  }
  .ant-table-tbody > tr > td{
    border-bottom: 1px dashed #f0f0f0;
  }
}
</style>